export default (
  state = {
    token: null,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case "LOGIN_SET_TOKEN":
      return {
        ...state,
        token: action.token,
      };

    case "LOGIN_SET_DATA":
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
};
