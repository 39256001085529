import React, { lazy, Suspense } from "react";
import { translate } from "../services/tools";

const { REACT_APP_MODULES } = process.env;

/**
 * Load a component if and only if its module is declared
 */
export function componentIfModule(name, fn) {
  const NotLoaded = () => <>Module {name} not compiled in</>;
  if (!REACT_APP_MODULES || REACT_APP_MODULES.includes(name)) return lazy(fn);
  else return NotLoaded;
}

/**
 * Load a component if and only if its module is declared, with a fallback
 */
export function componentIfModuleFallback(name, fn) {
  const Component = componentIfModule(name, fn);
  const Fallback = () => <div className="lc-fallback">{translate("loading")}</div>;

  return (props) => (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  );
}
