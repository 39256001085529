import React from "react";
import styled from "styled-components";
import { useMedia } from "../../hooks/useMedia";
import { assetsPath } from "../../services/tools";
import { luminance } from "luminance-js";

const { REACT_APP_PROJECT } = process.env;

const Color = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  background: ${(props) => "#" + (props.line.color || "888")};
  color: ${(props) =>
    props.line.text ? "#" + props.line.text : luminance("#" + props.line.color) > 0.5 ? "#333" : "#fff"};
  font-size: 1.4em;
  font-weight: bold;
  flex: 1;
  justify-self: ${(props) => props.mode.includes("tramway") && "center"};
  border-radius: ${(props) => props.mode.includes("tramway") && "50%"};
  width: ${(props) => props.mode.includes("tramway") && "25px"};
  max-width: ${(props) => props.mode.includes("tramway") && "25px"};
  margin-right: ${(props) => props.mode.includes("tramway") && "10px"};

  &:hover {
    opacity: 0.8;
  }
`;

const Image = styled.div.attrs((props) => ({
  style: {
    backgroundImage: "url(" + assetsPath(`/assets/images/lines/${encodeURI(props.line.code)}.svg`) + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: !props.mode.includes("bus") && props.line.cat && (props.isDesktop ? "42px" : "34px"),
    height: props.isDesktop ? "42px" : "34px",

    marginRight: props.mode.includes("tramway") && props.grid && "10px",

    flex: !props.mode.includes("tramway") && 1,
  },
}))`
  &:hover {
    opacity: 0.8;
  }
`;

export const UILine = (props) => {
  const isDesktop = useMedia();

  switch (REACT_APP_PROJECT) {
    case "liege-tec-network":
      const codeBefore =
        props.line.codeBefore !== props.line.codeAfter
          ? `${props.line.codeBefore.split(";").join(" + ")} ${
              props.line.codeBefore !== "" && props.line.codeAfter ? " > " : ""
            }`
          : "";

      const codeAfter = props.line.codeAfter.split(";").join(" & ");

      return (
        <Color {...props} isDesktop={isDesktop} className={"lc-uiline lc-" + props.line.code}>
          {codeBefore === "" && codeAfter !== "" && <span className="lc-only-code-after">{codeAfter}</span>}
          {codeBefore !== "" && codeAfter === "" && <span className="lc-only-code-before">{codeBefore}</span>}
          {codeBefore !== "" && codeAfter !== "" && (
            <>
              <span className="lc-code-before">{codeBefore}</span>
              <span className="lc-code-after">{codeAfter}</span>
            </>
          )}
        </Color>
      );

    default:
      return props.image ? (
        <Image {...props} isDesktop={isDesktop} className={"lc-uiline lc-uiline-image " + props.mode} />
      ) : (
        <Color {...props} isDesktop={isDesktop} className={"lc-uiline"}>
          {props.line.code}
        </Color>
      );
  }
};

UILine.defaultProps = {
  line: null,
  mode: "",
};
