import React from "react";
import { useOnScreen } from "../hooks/useOnScreen";

export function OnScreen({ children }) {
  const [ref, onScreen] = useOnScreen();

  return (
    <div className="lc-map" aria-hidden="true" tabIndex="-1" ref={ref} data-lc-map>
      {children(onScreen)}
    </div>
  );
}
