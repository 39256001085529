import styled from "styled-components";
import { primarycolor } from "../../scss/app.scss";

export const UploadArea = styled.div`
  height: 200px;
  border-style: none;
  background-color: ${primarycolor};
  color: ${(props) => (props.color > 0.5 ? "#333" : "#fff")};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 275px;
  margin: 15px 15px 0 0;
`;

export const UploadContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  justify-content: space-evenly;
  padding: 0 15px;

  label {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }

  input {
    display: none;
    z-index: -1;
  }

  span {
    font-style: italic;
    font-size: 0.875em;

    span {
      font-style: initial;
    }
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  color: white;
`;

export const UploadName = styled.div`
  text-align: center;
`;

export const UpdateClick = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
`;
