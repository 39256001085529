import React from "react";
import { envVarToBool, translate } from "../../services/tools";

const { REACT_APP_SHOW_PMR, REACT_APP_SHOW_PMR_TRANSLATION } = process.env;

const UIStopPmr = (props) => {
  const { pmr, displayon } = props;

  if (["pmr", "both"].includes(REACT_APP_SHOW_PMR) && pmr === true) {
    if (displayon === "popup" && envVarToBool(REACT_APP_SHOW_PMR_TRANSLATION)) {
      return (
        <div className="lc-pmr-text">
          <div
            className="lc-is-pmr"
            aria-label={`${translate("stop", false)} ${translate("aria-lines-stop-pmr", false)}`}
          />
          <div>{translate("stop-pmr")}</div>
        </div>
      );
    } else {
      return (
        <div
          className="lc-is-pmr"
          aria-label={`${translate("stop", false)} ${translate("aria-lines-stop-pmr", false)}`}
        />
      );
    }
  }

  if (["no-pmr", "both"].includes(REACT_APP_SHOW_PMR) && pmr === false) {
    if (displayon === "popup" && envVarToBool(REACT_APP_SHOW_PMR_TRANSLATION)) {
      return (
        <div className="lc-pmr-text">
          <div className="lc-is-no-pmr" />
          <div>{translate("stop-no-pmr")}</div>
        </div>
      );
    } else {
      return <div className="lc-is-no-pmr" />;
    }
  }

  return <></>;
};

export default UIStopPmr;
