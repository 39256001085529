import styled, { css } from "styled-components";
import { primarycolor } from "../../../scss/app.scss";
import { assetsPath } from "../../../services/tools";

export const PlaceInfos = styled.div`
  flex-direction: column;
  margin: 0;
  font-weight: 300;
  border-bottom: ${(props) => (props.displayon === "board" ? `1px solid ${primarycolor}` : "none")};
  padding: ${(props) => (props.displayon === "board" ? "10px 0" : "0")};
`;

export const PlaceInfo = styled.div`
  margin: 0 0 5px 0;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const PlaceInfoFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const PlaceInfoInline = styled.div`
  display: block;
  & > div {
    display: inline;
  }
`;

export const PlaceInfoOpeningHours = styled.div`
  margin: 5px 0px;
  .lc-opening-hours {
    flex-direction: column;

    .lc-opening-hours-title {
      font-weight: 500;
    }

    .lc-opening-hours-list {
      flex-direction: column;

      span {
        display: block;
      }
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const PlaceInfoTitle = styled.div`
  font-weight: 500;
`;

export const PlaceInfoContent = styled.div`
  font-weight: 300;
  display: flex;
  align-items: center;
`;

export const PlaceRealtimeInfo = styled.div`
  background: #e0e0e0;
  border-radius: 5px;
  padding: 3px;
  align-self: flex-start;
  margin: 5px 0px;
`;

export const RealtimeImage = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 3px;
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: 5px;
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;

export const PRPlaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .lc-pr-address {
    padding-bottom: 5px;
  }
  .lc-pr-time {
    white-space: nowrap;
    font-size: ${(p) => (p.displayon === "board" ? "0.875em" : "inherit")};
  }
  .lc-pr-slots-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .lc-pr-realtime-seats {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.5em;

    &:not(.lc-no-available-places):before {
      content: "";
      background: url(${() => assetsPath("/assets/images/places/p+r_slots.svg")}) no-repeat center;
      background-size: contain;
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }

    &.lc-no-available-places {
      font-size: unset;
      font-weight: initial;
      padding: 3px 0 10px;
      white-space: initial;
    }

    .lc-slots {
      margin-left: 5px;
      font-size: 0.8em;
    }
  }
  .lc-pr-realtime-elec-slots {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.5em;

    &:before {
      content: "";
      background: url(${() => assetsPath("/assets/images/places/electric_slots.svg")}) no-repeat center;
      background-size: contain;
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }
  .lc-pr-realtime-pmr-slots {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.5em;

    &:before {
      content: "";
      background: url(${() => assetsPath("/assets/images/places/pmr_slots.svg")}) no-repeat center;
      background-size: contain;
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }
  .lc-pr-more-infos {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 0 0;
    font-size: 0.9em;
  }

  /* border-bottom: none !important; */
  padding: 0px !important;
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.displayon === "board" &&
    css`
      align-items: flex-start !important;
      gap: 5px;
    `}
`;

export const VCubPlaceInfos = styled.div`
  border-bottom: none !important;
  padding: 0;
  margin: 0;
  text-align: center;
  padding: 2px 5px 5px;

  .lc-bss {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: inherit;
    padding: 5px 0 10px;
    font-weight: bold;
    font-size: ${(p) => (p.displayon === "board" ? "1.3125em" : "1.5em")};

    .lc-bikes,
    .lc-electric-bikes,
    .lc-seats {
      display: flex;
      align-items: flex-end;

      &:not(:first-child) {
        padding-left: 35px !important;
      }
    }
    .lc-bikes {
      &:before {
        content: "";
        background: url("${() => assetsPath("/assets/images/places/vcub_bikes.svg")}") no-repeat center;
        width: 34px;
        height: 30px;
        margin-right: 6px;
      }
    }
    .lc-electric-bikes {
      &:before {
        content: "";
        background: url("${() => assetsPath("/assets/images/places/vcub_electric_bikes.svg")}") no-repeat center;
        width: 34px;
        height: 30px;
        margin-right: 6px;
      }
    }
    .lc-seats {
      &:before {
        content: "";
        background: url("${() => assetsPath("/assets/images/places/vcub_slots.svg")}") no-repeat center;
        width: 12px;
        height: 30px;
        margin-right: 6px;
        margin-bottom: -2px;
      }
    }
  }
  .lc-bss-time {
    white-space: nowrap;
    font-size: ${(p) => (p.displayon === "board" ? "0.875em" : "inherit")};
  }
  .lc-vcub-more-infos {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 0 0;
    font-size: 0.9em;
  }

  ${(props) =>
    props.displayon === "board" &&
    css`
      padding: 0;
      margin-top: -10px;
      text-align: left;
    `}
`;

export const BikePrices = styled.ul`
  padding-inline-start: 0px;
  list-style-type: none;
`;

export const BikeBss = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
`;

export const BikeBssDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  img {
    height: 15px;
    padding-left: 5px;
  }
`;

export const PictoBike = styled.img`
  height: 15px;
  padding-right: 5px;
`;

export const PlaceCars = styled.div`
  margin-left: 0;
  display: flex;
  align-items: baseline;
  margin-top: 5px;
  .lc-realtime-gif {
    width: 15px;
  }
`;

export const PlaceCar = styled.div`
  margin-right: 10px;
  img {
    margin-left: 5px;
    width: 30px;
  }
`;

export const CustomText = styled.div`
  font-size: 0.875em;
  display: flex;
  margin-top: 8px;
  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
`;

export const StationSchedulesHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 0;
  font-weight: 400;

  display: flex;
  div:nth-child(1) {
    flex: 2;
  }

  div:nth-child(2) {
    flex: 3;
    display: flex;
    flex-direction: column;
  }

  del {
    font-size: 0.9em;
    color: #999;
  }
`;

export const StationSchedulesContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-weight: 500;
  min-height: 20px;

  &.lc-not-empty {
    min-height: 100px;
  }
`;

export const StationSchedulesEntry = styled.div`
  display: flex;
  div:nth-child(1) {
    flex: 2;
  }

  div:nth-child(2) {
    flex: 3;
    display: flex;
    flex-direction: column;
  }

  del {
    font-size: 0.9em;
    color: #999;
  }
`;

export const StationSchedulesEntryMode = styled.span`
  font-size: 0.9em;
  color: #aaa;
  font-weight: 400;
`;

export const StationPagination = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
`;

export const StationPaginationNext = styled.div`
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 15px;
    height: 15px;
    transform: rotate(-90deg);
  }
`;

export const StationPaginationPrevious = styled.div`
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 0 2px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 15px;
    height: 15px;
    transform: rotate(90deg);
  }
`;

export const StationSchedules = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;

  div {
    flex: initial;
    margin-left: initial;
    justify-content: initial;
  }
`;

export const EntranceLines = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 0px;
  margin-bottom: 0px;

  .lc-uiline-image {
    flex: 0 0 auto !important;
  }
  .lc-entrance-mode {
    width: 20px;
    margin-left: 5px;
  }
`;
export const EntranceAccess = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 5px;
  img {
    height: 28px;
    width: 28px;
  }
`;

export const VerticalDivider = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: solid #888 1px;
`;
