import {
  buildAutocomplete,
  buildPlacesByCatInList,
  buildAroundPreferences,
  buildRouteCalcPreferences,
  buildTabsPanel,
} from "../services/board";
import { resize } from "../services/tools";
import { actionGoToValid } from "../actions/board";

export default () => {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      switch (action.type) {
        case "BUILD_AUTOCOMPLETE":
          return buildAutocomplete(getState(), action.inputProps, action.isModal);

        case "ON_INPUT_VALUE_CHANGE":
          if (action.value.length === 0) {
            dispatch(actionGoToValid(false));
          }

          break;

        case "BUILD_PLACES_BY_CAT_IN_LIST":
          setTimeout(() => resize(getState().app.isMobile));
          return buildPlacesByCatInList(getState(), action.places);

        case "BUILD_AROUND_PREFERENCES":
          return buildAroundPreferences(getState());

        case "BUILD_ROUTE_CALC_PREFERENCES":
          return buildRouteCalcPreferences(getState());

        case "BUILD_TABS_PANEL":
          return buildTabsPanel(getState());

        default:
          break;
      }

      return next(action);
    };
};
