import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "../middlewares/axios";
import { appStore } from "../store";
import { assetsPath, translate } from "../services/tools";
import { UploadArea, UploadContent, UploadName, Link, UpdateClick } from "./styled/UIUploadArea";
import { luminance } from "luminance-js";
import { primarycolor } from "../scss/app.scss";

const { REACT_APP_ADMIN, REACT_APP_PROJECT, REACT_APP_ENV } = process.env;

const Admin = () => {
  const updateData = JSON.parse(REACT_APP_ADMIN);
  const params = JSON.parse(REACT_APP_ADMIN)?.params;
  const isValkyrie = params?.valkyrie || null;
  let valkyrieURL = params?.externalValkyrie || "valkyrie.lc.tools";
  const valkyrieProject = params?.projectValkyrie || REACT_APP_PROJECT;

  if (!valkyrieURL.startsWith("http")) valkyrieURL = `https://${valkyrieURL}`;

  delete updateData.params;

  const [indicator, setIndicator] = useState({ type: null, message: null });
  const [isLoading, setIsLoading] = useState(false);

  const displayUpdateStatus = (status, message = null) => {
    switch (status) {
      case "loading":
        setIndicator({ type: "loading", message: translate(`admin-update-loading${message ? "-" + message : ""}`) });
        break;
      case "success":
        setIndicator({ type: "success", message: translate(`admin-update-success${message ? "-" + message : ""}`) });
        break;
      default:
        setIndicator({
          type: "error",
          message: translate(`admin-update-error${message ? "-" + message : ""}`) || message,
        });
        break;
    }
  };

  const launchUpdateGtfs = async (coverage, file, g2n, dante, sendGTFS, merge, gtfsToMerge, name) => {
    console.log("Launch GTFS update");

    try {
      const update = await axios(
        `https://thor.lc.tools/mjolnir/update?project=${REACT_APP_PROJECT}&coverage=${coverage}&file=${file}&g2n=${
          g2n || false
        }&dante=${dante || false}&send=${sendGTFS || false}${merge ? "&merge=" + gtfsToMerge + "&name=" + name : ""}`
      );

      let status = undefined;

      // every 10 sec call api to know if current job is done
      const waitEndUpdate = (id, status) => {
        if (["done", "failed"].includes(status)) {
          if (status === "failed") {
            console.warn("Tyr returned error importing GTFS.");
            displayUpdateStatus("error", "fail-gtfs");
          } else {
            // Update data map
            console.info("Data update success.");

            updateMapData("data", "lines");
          }
        } else {
          return new Promise((resolve) => setTimeout(resolve, 10000)).then(async () => {
            const response = await axios(`https://thor.lc.tools/mjolnir/update-status?id=${id}`);

            if (response.data) {
              waitEndUpdate(id, response.data.status);
            }
          });
        }
      };

      if (!file.endsWith(".csv")) {
        waitEndUpdate(update.data.job, status);
      } else {
        // need to create a Thor API for return jobs
        setTimeout(async () => {
          const getStatus = async (coverage) => {
            const jobs = await axios(`https://tyr.lc.tools/v0/jobs`);
            const job = jobs.data.jobs.find((j) => j.instance.name === coverage);

            return job.state;
          };

          const status = await getStatus(coverage);

          if (["done", "failed"].includes(status)) {
            if (status === "failed") {
              console.warn("Tyr returned error importing GTFS.");
              displayUpdateStatus("error", "fail-gtfs");
            } else {
              // Update data map
              console.info("Data update success.");

              updateMapData("data", "lines");
            }
          } else {
            console.warn("Take too long time...");
            displayUpdateStatus("error", "fail-gtfs");
          }
        }, 15000);
      }
    } catch (e) {
      console.warn("Error while calling Thor...");
      displayUpdateStatus("error", "fail-gtfs");
    }
  };

  const handleFiles = async (files, element) => {
    const { length } = files;

    switch (element.type) {
      case "gtfs":
        displayUpdateStatus("loading", "gtfs");

        // Data into Thor and update map if no errors during process
        try {
          if (length === 1) {
            const { name, type } = files[0];

            const accept = [
              { type: "application/x-zip-compressed", ext: ".zip" },
              { type: "application/zip", ext: ".zip" },
            ];

            const acceptType = accept.find((a) => a.type === type);

            if (acceptType && name.endsWith(acceptType.ext)) {
              const formData = new FormData();

              formData.append("coverage", element.coverage);
              formData.append("file", files[0]);
              const upload = await axios.post(`/api/upload`, formData, {
                withCredentials: true,
              });

              const { coverage, g2n, dante, sendGTFS, merge, name } = element;

              const gtfsToMerge = merge
                ? updateData.gtfs
                    .filter((g) => g.merge)
                    .map((g) => g.name)
                    .join(",")
                : null;

              if (upload.data && upload.data.status === "error" && coverage) {
                displayUpdateStatus("error", "fail-upload");
              } else {
                await launchUpdateGtfs(coverage, upload.data.file, g2n, dante, sendGTFS, merge, gtfsToMerge, name);
              }
            } else {
              displayUpdateStatus("error", "incorrect-format");
            }
          } else {
            displayUpdateStatus("error", "only-one-file");
          }
        } catch (error) {
          displayUpdateStatus("error");
        }

        break;

      default:
        displayUpdateStatus("error");
        break;
    }
  };

  const onDrop = (e, element) => {
    e.preventDefault();
    const {
      dataTransfer: { files },
    } = e;

    handleFiles(files, element);
  };

  const updateMapData = async (status = null, item = null) => {
    displayUpdateStatus("loading", status);
    console.info(`Launch map update${item ? " for " + item : ""}.`);
    axios({
      method: "get",
      url: `/api/generate/data${item ? "?use=" + item : ""}`,
      timeout: 10 * 60 * 1000,
    })
      .then((resp) => {
        console.info("Map update success.");
        displayUpdateStatus("success");
      })
      .catch((err) => {
        console.warn(err);
        displayUpdateStatus("error", "fail-update");
      });
  };

  // Update Xlsx file from drive
  const updateXlsxFromDrive = async (id, generate = null) => {
    displayUpdateStatus("loading");
    axios
      .get(`/api/admin?id=${id}&generate=${generate}`)
      .then((resp) => {
        if (generate) {
          updateMapData("data", "places");
        } else {
          displayUpdateStatus("success");
        }
      })
      .catch((err) => {
        displayUpdateStatus(
          "error",
          err.response && err.response.data && err.response.data.message ? err.response.data.message : null
        );
      });
  };

  const updateXlsxToGtfs = async (element) => {
    displayUpdateStatus("loading");
    const { id, coverage, g2n, dante, sendGTFS, merge, name } = element;

    const gtfsToMerge = merge
      ? updateData.gtfs
          .filter((g) => g.merge)
          .map((g) => g.name)
          .join(",")
      : null;

    axios
      .get(`/api/admin?id=${id}&coverage=${coverage}`)
      .then(async (resp) => {
        await launchUpdateGtfs(
          coverage,
          `gtfs-spreadsheets-${coverage}.csv`,
          g2n,
          dante,
          sendGTFS,
          merge,
          gtfsToMerge,
          name
        );
      })
      .catch((err) => {
        displayUpdateStatus(
          "error",
          err.response && err.response.data && err.response.data.message ? err.response.data.message : null
        );
      });
  };

  useEffect(() => {
    setIsLoading(indicator.type === "loading");
  }, [indicator]);

  return Object.entries(appStore.getState().app.languageFile).length !== 0 ? (
    isValkyrie ? (
      <div className="lc-admin-valkyrie" key="admin-valkyrie">
        <iframe
          title="valkyrie"
          src={`${valkyrieURL}/${valkyrieProject}`}
          frameBorder="0"
          referrerPolicy="origin"
        ></iframe>
      </div>
    ) : (
      <div className="lc-admin-center">
        <div className="lc-admin-header">
          <a href="/">
            <img
              alt={translate("back")}
              src={assetsPath("/assets/images/admin/back.svg")}
              className="lc-admin-header-back"
              height={28}
            />
          </a>
          <span>
            {translate("admin-title")}
            {REACT_APP_ENV !== "prod" ? " (" + REACT_APP_ENV + ")" : ""}
          </span>
        </div>
        {JSON.parse(REACT_APP_ADMIN)?.params?.force ? (
          <div className="lc-admin-content force-map-update">
            <UploadArea key={`admin-update-map`}>
              <UploadContent>
                <UploadName>{translate(`admin-update-map-datas`)}</UploadName>
                <label onClick={() => updateMapData(null, "lines")}>{translate(`admin-update-launch-button`)}</label>
              </UploadContent>
            </UploadArea>
          </div>
        ) : null}
        <div className="lc-admin-datas">
          {indicator.type && (
            <div
              className={`lc-admin-modal${indicator.type !== "loading" ? " lc-admin-modal-close" : ""}`}
              onClick={() => {
                if (indicator.type !== "loading") {
                  setIndicator({ type: null, message: null });
                }
              }}
            >
              <div
                className={`lc-admin-message ${
                  indicator.type && indicator.type === "error"
                    ? " message-error"
                    : indicator.type === "success"
                    ? " message-success"
                    : " message-loading"
                }`}
              >
                {indicator.type !== "loading" && (
                  <img
                    alt={translate("close")}
                    src={assetsPath("/assets/images/admin/close-modal.svg")}
                    className="lc-admin-modal-close"
                    height={15}
                  />
                )}
                {indicator.message}
                {isLoading && (
                  <div className="lc-loading" data-lc-loading>
                    <img src={assetsPath("/assets/images/loading.gif")} width={30} alt={translate("loading")} />
                  </div>
                )}
              </div>
            </div>
          )}
          {Object.keys(updateData).map((m) => {
            return (
              <div className="lc-admin-cat" key={m}>
                <div className="lc-admin-title">{translate(`admin-update-title-data-${m}`)}</div>
                <div className="lc-admin-content">
                  {updateData[m]?.map((element, index) => {
                    switch (element.type) {
                      case "xlsxFromDrive":
                        return (
                          <UploadArea key={`admin-${index}`} color={luminance(primarycolor)}>
                            <UploadContent>
                              <UploadName>{translate(`admin-update-${element.name}`)}</UploadName>
                              {element.url && (
                                <Link href={element.url} target="_blank" rel="noopener noreferrer">
                                  Lien vers le fichier
                                </Link>
                              )}
                              <UpdateClick onClick={() => updateXlsxFromDrive(element.id, element?.generate)}>
                                {translate("admin-update-launch-button")}
                              </UpdateClick>
                            </UploadContent>
                          </UploadArea>
                        );
                      case "gtfs-from-drive":
                        return (
                          <UploadArea key={`admin-${index}`} color={luminance(primarycolor)}>
                            <UploadContent>
                              <UploadName>{translate(`admin-update-${element.name}`)}</UploadName>
                              {element.url && (
                                <Link href={element.url} target="_blank" rel="noopener noreferrer">
                                  Lien vers le fichier
                                </Link>
                              )}
                              <UpdateClick onClick={() => updateXlsxToGtfs(element)}>
                                {translate("admin-update-launch-button")}
                              </UpdateClick>
                            </UploadContent>
                          </UploadArea>
                        );
                      case "gtfs":
                        return element.url ? null : (
                          <UploadArea
                            color={luminance(primarycolor)}
                            key={`admin-${index}`}
                            onDrop={(e) => onDrop(e, element)}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <UploadContent>
                              <UploadName>{translate(`admin-update-${element.name}`)}</UploadName>
                              <span>
                                <span role="img" aria-label="warning">
                                  ⚠️
                                </span>{" "}
                                {translate(`admin-update-warning-gtfs`)}
                              </span>

                              <label htmlFor="file">{translate("admin-update-add-zip")}</label>
                              <input
                                className="file"
                                id="file"
                                type="file"
                                onChange={(e) => handleFiles(e.target.files, element)}
                              />
                            </UploadContent>
                          </UploadArea>
                        );

                      default:
                        return null;
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  ) : (
    <div>Loading...</div>
  );
};

export default withRouter(Admin);
