import React from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { actionBuildAutocomplete } from "../../actions/withRedux";
import { assetsPath } from "../../services/tools";

const SearchContainer = styled.div`
  flex-grow: 1;

  .lc-form {
    padding-bottom: 0px !important;

    .lc-inputs {
      border: 2px solid rgba(0, 0, 0, 0.2);

      .lc-autocomplete {
        left: -40px;
        width: 355px !important;
      }
    }
  }
`;

const AroundInBoard = ({ withIcon }) => {
  const dispatch = useDispatch();

  return (
    <SearchContainer>
      <div className="lc-form">
        <div className={`lc-inputs${withIcon ? " lc-inputs-with-icon" : ""}`}>
          {withIcon && <img src={assetsPath("/assets/images/menu/around.svg")} alt="around" />}
          {dispatch(actionBuildAutocomplete("inputSearch"))}
        </div>
      </div>
    </SearchContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    inputValue: state.board.inputValue,
    inputItems: state.board.inputItems,
  };
};

export default connect(mapStateToProps)(AroundInBoard);
