import axios from "../middlewares/axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appStore } from "../store";
import {
  actionInitApp,
  actionInitConfig,
  actionInitModes,
  actionInitWalkingSpeeds,
  actionInitBikeSpeeds,
  actionInitbikeProfils,
  actionSetAppLanguage,
  actionSetAppLocker,
  actionSetAppPanelSize,
  actionSetAppTracking,
  actionSetUserToken,
  actionSetUserID,
  actionUpdateMediaQueries,
  actionSetHash,
  actionSetHeader,
  actionSetConfig,
  actionSetDontShow,
  actionSetWindowPrinting,
  actionInitDatePickerMinMaxDate,
  actionSetCalendarDate,
  actionInitDate,
  actionInitTravelSolutions,
} from "../actions/app";
import { Route, Router, Switch } from "react-router-dom";
import history from "../history";
import "../scss/app.scss";
import "tippy.js/dist/tippy.css";
import Map from "./Map";
import Board from "./Board";
import Modal from "./Modal";
import Admin from "./Admin";
import { actionBuildTerritoryOutline, actionHandleLegendState } from "../actions/map";
import { getURLSearchParams, isInFrame, translate } from "../services/tools";
import { envVarToBool } from "../services/tools";
import { actionSetNetworkSlider, actionSetNetworkSliderMode } from "../actions/network";
import { message } from "../services/message";
import { ScrollToTop } from "./ScrollToTop";
import { headerDirection } from "../scss/app.scss";
import { initPlausible } from "../tracking";
import Print from "./Print";
import styled from "styled-components";
import { componentIfModuleFallback } from "../utils/codeSplit";
import { toggleModalGeneric } from "../actions/modalGeneric";

const {
  REACT_APP_HEADER,
  REACT_APP_HEAVY_LINES,
  REACT_APP_LEGEND,
  REACT_APP_SLIDER,
  REACT_APP_TERRITORY_OUTLINE,
  REACT_APP_BOARD_SIZE,
  REACT_APP_API_PROXY_URL,
  REACT_APP_PROJECT,
  REACT_APP_LIBRARY_URL,
  REACT_APP_GTM,
  REACT_APP_ADMIN,
  REACT_APP_FORCE_DISABLE_COOKIES,
  REACT_APP_COMPLEMENTARY_LINES,
  REACT_APP_LAUNCH_MODAL,
} = process.env;

const LeafletBretagneCanaux = componentIfModuleFallback("specific/LeafletBretagneCanaux", () =>
  import("./specific/LeafletBretagneCanaux")
);

const Cookies = styled.div`
  background: #f1f5f5;
  position: ${(props) => (props.isMobile ? "sticky" : "relative")};
  bottom: ${(props) => props.isMobile && "23px"};
  padding: 10px;
  border-radius: 10px 10px 0 0;
  z-index: 500;
  font-size: 0.9em;
  display: ${(props) => !props.isMobile && "flex"};
  align-items: center;
  justify-content: center;
`;

const CookiesContent = styled.span`
  flex: 1;
`;

const CookiesButtons = styled.div`
  display: flex;
  justify-content: end;
`;

const CookiesButton = styled.div`
  background-color: #58585a;
  color: #ffffff;
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2) {
    margin-left: 10px;
  }

  &:hover {
    background-color: #808080;
  }
`;

function updateVh() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

class App extends Component {
  mediaQueries = window.matchMedia("(max-width: 600px)");
  languageFile = null;
  loaded = false;

  handleMediaQueryChanged = (event) => {
    appStore.dispatch(actionUpdateMediaQueries(event.matches));
  };

  async componentDidMount() {
    // Get config from wynd

    const requestConfig = await axios.get("/api/init-config");

    if (requestConfig.data) {
      appStore.dispatch(actionInitConfig(requestConfig.data));
    }

    const params = getURLSearchParams(history.location);

    updateVh();

    window.addEventListener("resize", () => {
      updateVh();
    });

    window.onbeforeprint = () => {
      appStore.dispatch(actionSetWindowPrinting(true));
    };

    window.onafterprint = () => {
      appStore.dispatch(actionSetWindowPrinting(false));
    };

    // Google Tag Manager
    if (REACT_APP_GTM && !window.document.cookie.includes("hasAuthorizedCookies")) {
      window.document.cookie = "hasAuthorizedCookies=undefined;path=/";
    }

    const hasCookieTrackingTrue = window.document.cookie.includes("hasAuthorizedCookies=true");

    if (params.hasAuthorizedCookies === "true" || hasCookieTrackingTrue) {
      appStore.dispatch(actionSetAppTracking(true));
    } else {
      initPlausible();
    }

    // store the token in redux
    if (params.token) {
      appStore.dispatch(actionSetUserToken(params.token));
    }

    if (params.client_id) {
      appStore.dispatch(actionSetUserID(1));
    }

    // set locker
    appStore.dispatch(actionSetAppLocker(params.lock));

    // set don't show
    if (params.dont_show) {
      appStore.dispatch(actionSetDontShow(params.dont_show));
    }

    // set panel size
    const boardSize = params.size ? params.size : params.board ? params.board : REACT_APP_BOARD_SIZE;

    appStore.dispatch(actionSetAppPanelSize(boardSize));

    // set legend
    if (REACT_APP_LEGEND === "all" || (REACT_APP_LEGEND === "web-only" && !this.mediaQueries.matches)) {
      appStore.dispatch(actionHandleLegendState(false));
    }

    // Dispatch action to build territor outline
    if (
      (REACT_APP_TERRITORY_OUTLINE || requestConfig?.data?.territory_outline) &&
      this.props.options?.config?.territory !== false
    ) {
      axios
        .get(`/api/file?name=territory_outline&folder=map&ext=geojson`)
        .then((response) => appStore.dispatch(actionBuildTerritoryOutline(response.data)));
    }

    try {
      const initParams = { params: {} };

      if (params.touchscreen) {
        initParams.params.touchscreen = params.touchscreen;
      }

      if (params.lang) {
        initParams.params.lang = params.lang;
      }

      if (this.props.options && this.props.options.lang) {
        initParams.params.lang = this.props.options.lang;
      }

      if (this.props.options?.config?.displayLinesAt || params.displayLinesAt) {
        initParams.params.displayLinesAt = this.props.options?.config?.displayLinesAt || params.displayLinesAt;
      }

      const requestInit = await axios.get("/api/init-application", initParams);
      const init = requestInit.data;

      this.languageFile = init.languageFile;

      // set hash
      appStore.dispatch(actionSetHash(init.hash));
      // set mobile
      init.isMobile = this.mediaQueries.matches;

      // set heavy lines
      init.heavyIds = REACT_APP_HEAVY_LINES
        ? JSON.parse(REACT_APP_HEAVY_LINES).length
          ? JSON.parse(REACT_APP_HEAVY_LINES)
          : JSON.parse(REACT_APP_HEAVY_LINES) === true
          ? true
          : []
        : [];

      init.complementaryIds = REACT_APP_COMPLEMENTARY_LINES
        ? JSON.parse(REACT_APP_COMPLEMENTARY_LINES).length > 0
          ? init.lines
              .filter((line) => JSON.parse(REACT_APP_COMPLEMENTARY_LINES).includes(line.mode))
              .map((line) => line.id)
          : []
        : [];

      // set modes route-calculation if module exist
      const routeCalculationModule = init.modules.find((m) => m.id === "route-calculation");

      if (envVarToBool(REACT_APP_HEADER)) {
        const response = await fetch(process.env.PUBLIC_URL + "/header.html");
        const header = await response.text();

        appStore.dispatch(actionSetHeader(header));
      }

      if (routeCalculationModule) {
        const modes = {};

        for (const mode of routeCalculationModule.modes) {
          modes[mode.type] = mode.defaultValue !== undefined ? mode.defaultValue : mode.value;
        }

        if (routeCalculationModule.hidePmr === undefined || routeCalculationModule.hidePmr === false) {
          modes["pmr"] = history.location.search.includes("pmr");
        }

        modes["avoid"] = "";
        appStore.dispatch(actionInitModes(modes));

        if (routeCalculationModule.walkingSpeeds !== undefined && routeCalculationModule.walkingSpeeds.length > 0) {
          appStore.dispatch(actionInitWalkingSpeeds(routeCalculationModule.walkingSpeeds));
        }

        if (routeCalculationModule.bikeSpeeds !== undefined && routeCalculationModule.bikeSpeeds.length > 0) {
          appStore.dispatch(actionInitBikeSpeeds(routeCalculationModule.bikeSpeeds));
        }

        if (routeCalculationModule.bikeProfiles !== undefined && routeCalculationModule.bikeProfiles.length > 0) {
          appStore.dispatch(actionInitbikeProfils(routeCalculationModule.bikeProfiles));
        }

        if (routeCalculationModule.travelSolutions !== undefined && routeCalculationModule.travelSolutions.length > 0) {
          appStore.dispatch(actionInitTravelSolutions(routeCalculationModule.travelSolutions));
        }

        let dateSelected = new Date();
        let now = new Date();

        if (
          routeCalculationModule.minDate !== undefined ||
          routeCalculationModule.maxDate !== undefined ||
          routeCalculationModule.defaultDateTime !== undefined
        ) {
          appStore.dispatch(
            actionInitDatePickerMinMaxDate(
              routeCalculationModule.minDate,
              routeCalculationModule.maxDate,
              routeCalculationModule.defaultDateTime
            )
          );

          if (routeCalculationModule.defaultDateTime !== undefined) {
            dateSelected = new Date(routeCalculationModule.defaultDateTime);
            appStore.dispatch(actionSetCalendarDate(new Date(dateSelected)));
          } else {
            if (routeCalculationModule.minDate !== undefined && new Date(routeCalculationModule.minDate) > now) {
              dateSelected = new Date(routeCalculationModule.minDate);
              dateSelected.setHours(10);
              appStore.dispatch(actionSetCalendarDate(dateSelected));
            }

            if (routeCalculationModule.maxDate !== undefined && new Date(routeCalculationModule.maxDate) < now) {
              dateSelected = new Date(routeCalculationModule.maxDate);
              dateSelected.setHours(now.getHours());
              dateSelected.setMinutes(now.getMinutes());
              appStore.dispatch(actionSetCalendarDate(dateSelected));
            }
          }
        }

        // Init Route Calc selected date
        appStore.dispatch(actionInitDate(dateSelected));
      }

      // set language
      appStore.dispatch(actionSetAppLanguage(init.lang));
      // update HTML lang attribute
      document.documentElement.lang = init.lang;

      // Default main DOM element
      init.domElement = "#lcmap";

      const documentReferer = document.referrer ? new URL(document.referrer).origin : null;

      init.documentReferer =
        documentReferer && (isInFrame() || REACT_APP_LIBRARY_URL)
          ? documentReferer
          : REACT_APP_API_PROXY_URL || window.location.origin;

      init.customMapLines = [];
      init.customMapMarkers = [];

      const { options } = this.props;

      if (options) {
        if (options?.route === "" && options?.lines?.length > 0) {
          init.customMapLines = options.lines;
        }

        if (options?.markers?.length > 0) {
          init.customMapMarkers = options.markers;
        }
      }

      // set everything else
      appStore.dispatch(actionInitApp(init));
      this.loaded = true;

      // Slider mode
      if (envVarToBool(REACT_APP_SLIDER) || (REACT_APP_SLIDER && JSON.parse(REACT_APP_SLIDER).modes?.length > 0)) {
        appStore.dispatch(actionSetNetworkSliderMode(true));
      } else {
        appStore.dispatch(actionSetNetworkSlider(false));
      }
    } catch (e) {
      console.warn("Error : app won't init : ", e);
    }

    this.mediaQueries.addListener(this.handleMediaQueryChanged);

    if (envVarToBool(REACT_APP_LAUNCH_MODAL) && !window.document.cookie.includes("launchModal=true")) {
      appStore.dispatch(toggleModalGeneric("launch-modal"));
    }

    // Send a component loaded message
    message({ loaded: "app" });
  }

  componentWillUnmount() {
    this.mediaQueries && this.mediaQueries.removeListener(this.handleMediaQueryChanged);
  }

  handleCookies = (accept) => {
    appStore.dispatch(actionSetAppTracking(accept));
    document.querySelector(".cookies-banner").style.display = "none";
  };

  render() {
    const { isMobile, show, modules, options, header, size, print, configApp } = this.props;
    const { pathname, search } = history.location;
    const params = getURLSearchParams(history.location);

    if (Object.keys(modules).length === 1) {
      if (pathname === "/") {
        history.push({
          pathname: `${pathname}${modules[0].id}`,
          search: search.split("&date=")[0],
        });
      }
    }

    if (options?.route && pathname === "/") {
      history.push((!options.route.startsWith("/") ? "/" : "") + options.route);
    } else if (options?.menu?.links) {
      const modulesToHide = Object.keys(options.menu.links).filter((link) => !options.menu.links[link]);

      if (modules.length - modulesToHide.length === 1) {
        const { pathname } = history.location;
        const modulesToShow = modules.map((m) => (m.id === "thematic" ? m.data : m.id));
        const moduleToRedirect = modulesToShow.find((m) => !modulesToHide.includes(m));

        if (pathname === "/") {
          history.push({
            pathname: `${pathname}${moduleToRedirect}`,
          });
        }
      }
    }

    // Edit app config if pass as params

    if (options?.config) {
      if (params.displayLinesAt) {
        options.config.displayLinesAt = params.displayLinesAt;
      }

      appStore.dispatch(actionSetConfig(options.config));
    } else {
      if (params.displayLinesAt) {
        appStore.dispatch(actionSetConfig({ displayLinesAt: params.displayLinesAt }));
      }
    }

    let showBoard = true;

    if (
      (this.loaded && modules.length === 0) ||
      (!options?.config?.size && !size) ||
      (options?.menu?.show !== undefined && options?.menu?.show === false)
    ) {
      showBoard = false;
    }

    // if (options !== undefined && options.route !== undefined && options.route === "") {
    //   showBoard = false;
    // }

    const RouterContent = () => {
      const { pathname } = history.location;

      if (REACT_APP_ADMIN && pathname === JSON.parse(REACT_APP_ADMIN)?.params?.entry) {
        return <Admin />;
      } else {
        switch (REACT_APP_PROJECT) {
          case "bretagne-canaux":
            return <LeafletBretagneCanaux startOnMobile={this.mediaQueries.matches} />;
          default:
            return (
              <>
                {!print && (
                  <Map configApp={configApp} startOnMobile={this.mediaQueries.matches} showBoard={showBoard} />
                )}

                <Switch>
                  {modules.map((module, index) => (
                    <Route
                      key={module.id + "_" + index}
                      path={`/${
                        ["thematic", "text-board", "projects", "lines-regions"].includes(module.id)
                          ? module.data
                          : module.url || module.id
                      }`}
                      component={() => <Board module={module} options={options} showBoard={showBoard} />}
                    />
                  ))}
                  <Route key="print" path="/print" component={() => <Print />} />
                  <Route
                    component={() => (
                      <Board isMobileBoot={this.mediaQueries.matches} options={options} showBoard={showBoard} />
                    )}
                  />
                </Switch>
              </>
            );
        }
      }
    };

    return (
      <>
        {envVarToBool(REACT_APP_HEADER) && pathname !== "/admin" && (
          <header
            className={
              (headerDirection === "column" || isMobile ? "lc-header-column" : "lc-header-row") +
              (isMobile ? "-mobile" : "")
            }
            dangerouslySetInnerHTML={{ __html: header }}
          />
        )}
        <Router history={history}>
          {RouterContent()}
          {show && <Modal />}
          {(!options || options?.features?.scrollToTop) && <ScrollToTop />}
          {((REACT_APP_GTM && !window.document.cookie.includes("hasAuthorizedCookies")) ||
            window.document.cookie.includes("hasAuthorizedCookies=undefined")) &&
            !envVarToBool(REACT_APP_FORCE_DISABLE_COOKIES) && (
              <Cookies className="cookies-banner" isMobile={isMobile}>
                <CookiesContent
                  dangerouslySetInnerHTML={{ __html: translate("cookies-header", false) }}
                ></CookiesContent>
                <CookiesButtons>
                  <CookiesButton onClick={() => this.handleCookies(true)}>{translate("cookies-accept")}</CookiesButton>
                  <CookiesButton onClick={() => this.handleCookies(false)}>{translate("cookies-refuse")}</CookiesButton>
                </CookiesButtons>
              </Cookies>
            )}
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.app.isMobile,
    modules: state.app.modules,
    show: state.modal.show,
    header: state.app.header,
    size: state.app.size,
    print: state.app.print.isPrinting,
    configApp: state.app.configApp,
  };
};

export default connect(mapStateToProps)(App);
