import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "../../services/message";
import { getLine, handleKeyPress, translate, assetsPath, isActiveModule } from "../../services/tools";
import { luminance } from "luminance-js";
import history from "../../history";
import { actionBuildComplementaryLines } from "../../actions/withRedux";

const { REACT_APP_LINES_TYPE_EXCEPTIONS, REACT_APP_LINES_MAIN_TYPE } = process.env;

const UIIntersecPopup = (props) => {
  const propsLines = props.lines;
  const popup = props.popup;
  const { lock, linesModes, size, lines, modules } = useSelector((state) => state.app);
  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";
  const gridRows = Math.ceil(propsLines.length / 2);
  const canClickLine = !lock;
  const dispatch = useDispatch();

  const handleClickLine = (e, line) => {
    message({ clicked: "line", id: line.id });

    dispatch(actionBuildComplementaryLines());

    if (popup) {
      popup.remove();
    }

    if (isActiveModule("around")) {
      history.push({
        pathname: "/around",
        search: "?line=" + line.id,
      });
    } else if (modules.find((m) => m.id === "lines")) {
      history.push({
        pathname: "/lines",
        search: "?current=" + line.id,
      });
    } else if (modules.find((m) => m.id === "multimobilities")) {
      const multimobilities = modules.find((m) => m.id === "multimobilities");
      const tabLines = multimobilities.mobilities.find((m) => m.data.map((d) => d.type).includes("lines"));

      if (tabLines) {
        history.push({
          pathname: "/multimobilities",
          search: "?mob=" + tabLines.id + "&current=" + line.id,
        });
      }
    } else {
      console.warn("No module with lines");
    }
  };

  const handleMouseEnter = (e, line) => {
    dispatch(actionBuildComplementaryLines(line));
  };

  const handleMouseLeave = (e, line) => {
    dispatch(actionBuildComplementaryLines());
  };

  return (
    <div className="lc-infobox">
      <div className="lc-infobox-title">{translate("lignes-through-this-area")}</div>
      <div className="lc-infobox-content">
        <div
          className={`lc-infobox-lines lc-${size} ${
            styleLine.includes("WithDirection") ? "lc-line-with-direction" : ""
          }`}
          style={
            styleLine.includes("WithRouteDirection")
              ? {
                  gridTemplateRows: `repeat(${gridRows}, 1fr)`,
                }
              : {}
          }
        >
          {propsLines.map((line) => {
            // Retrieve the global line
            line = getLine(lines, line);

            if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
              const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
              const foundExceptedLine = exceptions.find((e) => e.lines.includes(line.id));

              if (foundExceptedLine) {
                styleLine = foundExceptedLine.type;
              }
            }

            switch (styleLine) {
              case "modeWithDirection":
                const lineMode = linesModes.find((mode) => mode.modes.includes(line.mode));

                return (
                  <div
                    className="lc-attribute-line"
                    key={line.id}
                    onClick={(e) => handleClickLine(e, line)}
                    onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line))}
                    onMouseEnter={(e) => handleMouseEnter(e, line)}
                    onMouseLeave={(e) => handleMouseLeave(e, line)}
                    role={canClickLine ? "button" : "img"}
                    tabIndex="0"
                    aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                  >
                    <div
                      className="lc-line lc-mode"
                      style={{
                        background: "#" + line.color,
                        color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {lineMode.name}
                    </div>
                    <div className="lc-name">{line.name}</div>
                  </div>
                );
              case "codeWithDirection":
                return (
                  <div
                    className="lc-attribute-line"
                    key={line.id}
                    onClick={(e) => handleClickLine(e, line)}
                    onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line))}
                    onMouseEnter={(e) => handleMouseEnter(e, line)}
                    onMouseLeave={(e) => handleMouseLeave(e, line)}
                    role={canClickLine ? "button" : "img"}
                    tabIndex="0"
                    aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                  >
                    <div
                      className="lc-line lc-code"
                      style={{
                        background: "#" + line.color,
                        color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {line.code}
                    </div>
                    <div className="lc-name">{line.name}</div>
                  </div>
                );
              case "imageWithRouteDirection":
              case "image":
                return (
                  <div
                    className="lc-line"
                    key={line.id}
                    onClick={(e) => handleClickLine(e, line)}
                    onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line))}
                    onMouseEnter={(e) => handleMouseEnter(e, line)}
                    onMouseLeave={(e) => handleMouseLeave(e, line)}
                    role={canClickLine ? "button" : "img"}
                    tabIndex="0"
                    aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                  >
                    <img
                      src={assetsPath("/assets/images/lines/" + line.code + ".svg")}
                      alt={line.code}
                      aria-hidden="true"
                    />
                    {styleLine === "imageWithRouteDirection" && line.direction && (
                      <div className="lc-name">{line.direction}</div>
                    )}
                  </div>
                );
              case "color":
                return (
                  <div
                    key={line.id}
                    className="lc-line"
                    onClick={(e) => handleClickLine(e, line)}
                    onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line))}
                    onMouseEnter={(e) => handleMouseEnter(e, line)}
                    onMouseLeave={(e) => handleMouseLeave(e, line)}
                    role={canClickLine ? "button" : "img"}
                    tabIndex="0"
                    aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                  >
                    <div
                      className="lc-line-code"
                      style={{
                        background: "#" + line.color,
                        color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                      }}
                    >
                      {line.code}
                    </div>
                  </div>
                );
              default:
                return "";
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default UIIntersecPopup;
