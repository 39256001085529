import "react-app-polyfill/ie11";
import { detect } from "detect-browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import outdated from "./outdated";
import { appStore } from "./store";
import axios from "./middlewares/axios";
import { isLocalOrDev } from "./services/tools";
import { receivedMessage } from "./services/message";
import AppWrapper from "./components/AppWrapper";

const browser = detect();
const element = "#lcmap";

/**
 * Render whole app
 * @param {*} options
 * @param {*} element
 */
const lcmap = async (options) => {
  // IE Error message
  if (browser.name === "ie") {
    outdated(browser, element);
    throw new Error("Internet Explorer n'est pas supporté");
  }

  if (process.env.REACT_APP_LIBRARY_URL) {
    if (!isLocalOrDev()) {
      let host = document.domain;

      if (host.startsWith("www.")) {
        host = host.replace("www.", "");
      }

      const response = await axios.get(`/api/validate-host?host=${host}`);

      if (!response.data.valid) {
        throw new Error("Vous n'êtes pas autorisé à afficher cette carte");
      }
    }

    // Throw an error if there is no DOM element
    if (!document.querySelector(element)) {
      throw new Error("Aucun élément avec l'id 'lcmap' n'a été trouvé dans le DOM");
    }
  } else {
    // Override default style if we are not using the app as a library
    document.body.style.margin = 0;
    // Temporary fix. TODO : change all index.html with a root ID of lcmap
    document.querySelector("#app") && document.querySelector("#app").setAttribute("id", "lcmap");
    document.querySelector(element).style.height = "calc(var(--vh, 1vh) * 100)";
  }

  await receivedMessage();
  ReactDOM.render(
    <Provider store={appStore}>
      <AppWrapper options={options} />
    </Provider>,
    document.querySelector(element)
  );

  outdated(browser, element);
};

// If we are not in library mode, just render the app as it is
if (!process.env.REACT_APP_LIBRARY_URL) {
  lcmap();
}

// Disable outlines on mouse interaction only !
(function (doc) {
  const style = doc.createElement("style");

  doc.getElementsByTagName("head")[0].appendChild(style);

  doc.addEventListener("mousedown", function () {
    style.innerHTML = "#lcmap *:focus{outline:0}";
  });

  doc.addEventListener("keydown", function (e) {
    if (e.key === "Tab") {
      style.innerHTML = "";
    }
  });
})(document);

export default lcmap;
