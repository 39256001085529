import React, { useEffect, useState } from "react";
import { assetsPath, translate } from "../../services/tools";
import axios from "../../middlewares/axios";

const UITitleBoard = (props) => {
  const { module, index, isMobile } = props;
  const [title, setTitle] = useState(translate(!module.submodule ? module.title : module.text));

  useEffect(() => {
    if (module.data && module.data.name && module.data.ext && module.data.field) {
      axios.get(`/api/file?name=${module.data.name}&ext=${module.data.ext}`).then((response) => {
        if (response && response.data) {
          setTitle(translate("text-board-title", false, { key: "date", value: response.data[0][module.data.field] }));
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div key={`${module.id}_${index}`} className="lc-menu-item lc-menu-item-title">
      {module.image && <img className="lc-images" src={assetsPath(module.image)} alt="" aria-hidden="true" />}
      <div className={!module.submodule ? "lc-menu-item-content" : ""}>
        <div
          className="lc-menu-title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {!isMobile && !module.submodule && module.description && (
          <div
            className="lc-menu-item-description"
            dangerouslySetInnerHTML={{
              __html: translate(module.description),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UITitleBoard;
