import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import Tippy from "@tippy.js/react";
import {
  displayPositionOnTrace,
  removePositionOnTrace,
  translate,
  unitsMetersToKm,
  zoomPositionOnTrace,
} from "../services/tools";

ChartJS.register(CategoryScale, LinearScale, ArcElement, PointElement, LineElement, Title, Tooltip, Legend);

const { REACT_APP_PRIMARY_COLOR } = process.env;

const calculElevationsData = (result) => {
  const labels = [],
    labelsOptimized = [],
    data = [],
    dataOptimized = [],
    minDist = 50,
    minHeight = 10,
    x = {
      min: 0,
      max: Math.round(Math.round(result[result.length - 1].distanceFromStart / minDist) * minDist),
    };

  result.forEach((elevation) => {
    labels.push(elevation.distanceFromStart);
    data.push(elevation.elevation);
  });

  const dataSort = [...data].sort();
  const middle = Math.ceil(data.length / 2);
  const median = data.length % 2 === 0 ? (dataSort[middle] + dataSort[middle - 1]) / 2 : dataSort[middle - 1];
  const y = { min: dataSort[0], max: dataSort[dataSort.len - 1], median };

  labels.forEach((dist, index) => {
    if (
      index === 0 ||
      index === labels.length - 1 ||
      dist - labelsOptimized[labelsOptimized.length - 1] > minDist ||
      Math.abs(data[index] - dataOptimized[dataOptimized.length - 1]) > minHeight
    ) {
      labelsOptimized.push(Math.round(dist / minDist) * minDist);
      dataOptimized.push(data[index]);
    }
  });

  return {
    data: dataOptimized,
    labels: labelsOptimized,
    x,
    y,
  };
};

export const GeoveloElevationChart = (journey, map = null) => {
  // TODO BSS will have many sections...
  const allValues = [...new Set([].concat(...journey.sections.map((s) => s.elevation)))];
  // TODO BSS will have many sections with each one beggining at 0m...
  const elevationData = calculElevationsData(allValues);

  return (
    <Line
      onMouseLeave={(e) => {
        removePositionOnTrace();
      }}
      options={{
        responsive: true,
        interaction: {
          mode: "index",
          intersect: false,
          axis: "x",
        },
        onHover: (e, item) => {
          // remove marker if exist
          removePositionOnTrace();

          // put new marker
          const value = item[0].element?.$context?.parsed?.x;

          if (value) {
            const closestValue = allValues.reduce((previous, current) => {
              return Math.abs(current.distanceFromStart - value) < Math.abs(previous.distanceFromStart - value)
                ? current
                : previous;
            });

            const coordsFromGeojson =
              journey?.sections[closestValue?.section]?.geojson?.coordinates[closestValue?.geometryIndex];

            if (map && coordsFromGeojson) {
              displayPositionOnTrace(
                {
                  lon: coordsFromGeojson[0],
                  lat: coordsFromGeojson[1],
                },
                "elevation"
              );
            }
          }
        },
        onClick: (e, item) => {
          const value = item[0].element?.$context?.parsed?.x;

          if (value) {
            const closestValue = allValues.reduce((previous, current) => {
              return Math.abs(current.distanceFromStart - value) < Math.abs(previous.distanceFromStart - value)
                ? current
                : previous;
            });

            const coordsFromGeojson =
              journey?.sections[closestValue?.section]?.geojson?.coordinates[closestValue?.geometryIndex];

            if (map) {
              zoomPositionOnTrace(map, [coordsFromGeojson[1], coordsFromGeojson[0]]);
            }
          }
        },
        animation: false,
        elements: {
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: (tooltipItems) => {
                return `${translate("elevation-tooltip-distance")} ${unitsMetersToKm(
                  parseFloat(tooltipItems[0].label.replace(",", ""))
                )}`;
              },
              label: (tooltipItem) => {
                return `${translate("elevation-tooltip-elevation")} ${unitsMetersToKm(tooltipItem.raw)}`;
              },
            },
          },
        },
        scales: {
          x: {
            type: "linear",
            min: elevationData.x.min,
            max: elevationData.x.max,
            ticks: {
              callback: function (value) {
                return unitsMetersToKm(value);
              },
            },
          },
          y: {
            type: "linear",
            suggestedMin: elevationData.y.median - 20,
            suggestedMax: elevationData.y.median + 20,
            ticks: {
              stepSize: 10,
              callback: function (value) {
                return unitsMetersToKm(value);
              },
            },
          },
        },
      }}
      data={{
        labels: elevationData.labels,
        datasets: [
          {
            data: elevationData.data,
            borderColor: REACT_APP_PRIMARY_COLOR,
            backgroundColor: REACT_APP_PRIMARY_COLOR,
            lineTension: 0.5,
          },
        ],
      }}
    />
  );
};

export const RouteFacilities = (data) => {
  const order = ["recommendedRoads", "normalRoads", "discouragedRoads"],
    { normalRoads, recommendedRoads, discouragedRoads } = data;

  const total = normalRoads + recommendedRoads + discouragedRoads;
  let rest = 100;

  return order.map((t, index) => {
    let percent = Math.round((data[[t]] / total) * 100);

    if (index !== 2) {
      rest -= percent;
    } else {
      percent = rest;
    }

    return `${percent}% ${translate(`route-calculation-stat-${t}`)}`;
  });
};

export const RouteFacilityStats = (data) => {
  const order = ["recommendedRoads", "normalRoads", "discouragedRoads"],
    { normalRoads, recommendedRoads, discouragedRoads } = data;

  const total = normalRoads + recommendedRoads + discouragedRoads;
  let rest = 100;

  return order.map((t, index) => {
    let percent = Math.round((data[[t]] / total) * 100);

    if (index !== 2) {
      rest -= percent;
    } else {
      percent = rest;
    }

    return (
      <div className="lc-route-calculation-stat" key={t}>
        <div className="lc-route-calculation-stat-bar">
          <Tippy
            theme={t}
            touch={["hold", 500]}
            placement={"top"}
            boundary="window"
            content={`${translate(`route-calculation-stat-${t}`)} : ${unitsMetersToKm(data[t])}`}
            key={t}
          >
            <div
              key={t}
              className={`lc-bike-road-facility ${t}`}
              style={{
                width: `${index !== 2 ? (data[[t]] / total) * 100 : percent}%`,
              }}
            ></div>
          </Tippy>
        </div>
        <div className={`lc-route-calculation-stat-percent ${t}`}>{percent}%</div>
        <div className="lc-route-calculation-stat-name">{translate(`route-calculation-stat-${t}`)}</div>
      </div>
    );
  });
};
