import React from "react";
import { assetsPath, translate } from "../services/tools";
import { Page, Header, Title, TitleText, TitleImg } from "./commun";
import { Summary, ItineraryMap, TextInBold } from "./commun";
import styled from "styled-components";

const MapContent = styled.div`
  padding-left: 10px;
`;

const PrintItineraryMap = (props) => {
  const { datas, page, configApp } = props;
  const { component, journey } = datas;

  return (
    <Page>
      <Header>
        <Title>
          <TitleImg src={assetsPath("/assets/images/menu/route-calculation.svg")} />
          <TitleText>{translate("print-page-title")}</TitleText>
        </Title>
      </Header>
      {page.summary && <Summary component={component} />}
      <MapContent>
        <TextInBold>{translate("print-page-my-trip", false)}</TextInBold>
        <ItineraryMap component={component} journey={journey} configApp={configApp} />
      </MapContent>
    </Page>
  );
};

export default PrintItineraryMap;
