import React from "react";
import { assetsPath, translate } from "../services/tools";

const renderIcon = (type, infos, image, className) => {
  if (image) {
    const alt =
      image === "modes/pmr"
        ? translate("aria-pmr", false)
        : image === "modes/walking-speed-slow"
        ? translate("route-calculation-walking-speed-slow", false)
        : image === "modes/walking-speed-normal"
        ? translate("route-calculation-walking-speed-normal", false)
        : image === "modes/walking-speed-fast"
        ? translate("route-calculation-walking-speed-fast", false)
        : image === "modes/walk"
        ? translate("modes-walk", false)
        : image === "leaf"
        ? translate("route-calculation-co2-emission-alt", false)
        : image === "leaf-not"
        ? translate("route-calculation-co2-not-emitted", false)
        : image === "calories"
        ? translate("route-calculation-calories", false)
        : image === "elevation-gain"
        ? translate("route-calculation-elevation-gain", false)
        : image === " elevation-loss"
        ? translate("route-calculation-elevation-loss", false)
        : "";

    return (
      <img
        className={className}
        src={assetsPath("/assets/images/" + (image.includes("leaf-not") ? "leaf" : image) + ".svg")}
        alt={alt}
        aria-hidden={alt === "" ? "true" : "false"}
      />
    );
  }

  switch (type) {
    case "walk":
      return (
        <svg className="lc-icon" alt="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" aria-hidden="true">
          <path d="M208 96c26.5 0 48-21.5 48-48S234.5 0 208 0s-48 21.5-48 48 21.5 48 48 48zm94.5 149.1l-23.3-11.8-9.7-29.4c-14.7-44.6-55.7-75.8-102.2-75.9-36-.1-55.9 10.1-93.3 25.2-21.6 8.7-39.3 25.2-49.7 46.2L17.6 213c-7.8 15.8-1.5 35 14.2 42.9 15.6 7.9 34.6 1.5 42.5-14.3L81 228c3.5-7 9.3-12.5 16.5-15.4l26.8-10.8-15.2 60.7c-5.2 20.8.4 42.9 14.9 58.8l59.9 65.4c7.2 7.9 12.3 17.4 14.9 27.7l18.3 73.3c4.3 17.1 21.7 27.6 38.8 23.3 17.1-4.3 27.6-21.7 23.3-38.8l-22.2-89c-2.6-10.3-7.7-19.9-14.9-27.7l-45.5-49.7 17.2-68.7 5.5 16.5c5.3 16.1 16.7 29.4 31.7 37l23.3 11.8c15.6 7.9 34.6 1.5 42.5-14.3 7.7-15.7 1.4-35.1-14.3-43zM73.6 385.8c-3.2 8.1-8 15.4-14.2 21.5l-50 50.1c-12.5 12.5-12.5 32.8 0 45.3s32.7 12.5 45.2 0l59.4-59.4c6.1-6.1 10.9-13.4 14.2-21.5l13.5-33.8c-55.3-60.3-38.7-41.8-47.4-53.7l-20.7 51.5z" />
        </svg>
      );
    case "wait":
      return (
        <svg className="lc-icon" alt="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" aria-hidden="true">
          <path d="M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z" />
        </svg>
      );
    case "bike":
      return (
        <svg className="lc-icon" alt="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" aria-hidden="true">
          <path d="M512.509 192.001c-16.373-.064-32.03 2.955-46.436 8.495l-77.68-125.153A24 24 0 0 0 368.001 64h-64c-8.837 0-16 7.163-16 16v16c0 8.837 7.163 16 16 16h50.649l14.896 24H256.002v-16c0-8.837-7.163-16-16-16h-87.459c-13.441 0-24.777 10.999-24.536 24.437.232 13.044 10.876 23.563 23.995 23.563h48.726l-29.417 47.52c-13.433-4.83-27.904-7.483-42.992-7.52C58.094 191.83.412 249.012.002 319.236-.413 390.279 57.055 448 128.002 448c59.642 0 109.758-40.793 123.967-96h52.033a24 24 0 0 0 20.406-11.367L410.37 201.77l14.938 24.067c-25.455 23.448-41.385 57.081-41.307 94.437.145 68.833 57.899 127.051 126.729 127.719 70.606.685 128.181-55.803 129.255-125.996 1.086-70.941-56.526-129.72-127.476-129.996zM186.75 265.772c9.727 10.529 16.673 23.661 19.642 38.228h-43.306l23.664-38.228zM128.002 400c-44.112 0-80-35.888-80-80s35.888-80 80-80c5.869 0 11.586.653 17.099 1.859l-45.505 73.509C89.715 331.327 101.213 352 120.002 352h81.3c-12.37 28.225-40.562 48-73.3 48zm162.63-96h-35.624c-3.96-31.756-19.556-59.894-42.383-80.026L237.371 184h127.547l-74.286 120zm217.057 95.886c-41.036-2.165-74.049-35.692-75.627-76.755-.812-21.121 6.633-40.518 19.335-55.263l44.433 71.586c4.66 7.508 14.524 9.816 22.032 5.156l13.594-8.437c7.508-4.66 9.817-14.524 5.156-22.032l-44.468-71.643a79.901 79.901 0 0 1 19.858-2.497c44.112 0 80 35.888 80 80-.001 45.54-38.252 82.316-84.313 79.885z" />
        </svg>
      );
    case "leaf":
    case "leaf-not":
      return (
        <svg className="lc-icon" alt="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" aria-hidden="true">
          <path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z" />
        </svg>
      );
    case "calories":
      return (
        <svg className="lc-icon" alt="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" aria-hidden="true">
          <path d="M60.05 72.81v.06a1.81 1.81 0 0 0 2 1.79A2.64 2.64 0 0 0 64.94 72v-.8a5.93 5.93 0 0 0-2.25-.43c-1.69 0-2.64.82-2.64 2.04Z" />
          <path d="M91.31 54.43c-5.62-25.75-21.1-28.3-22.19-27.23-1.24 1.22-2.05 3.27-1.75 8.5a77.24 77.24 0 0 0 1.75 11.62c-10.65-.59-9.47-15.38-9.47-15.38s0-10.36-2.37-16.28S36.42-3.62 31.94.93c-2.66 2.7 4.33 5.85 4 11.77a11.75 11.75 0 0 1-5 8.88S7.86 33.42 7.57 56.2s10.35 43.8 42 43.8 47.37-19.83 41.74-45.57ZM31.16 77.91l-3.31-5.59-1.49 1.73v3.86h-5.77V55.73h5.77V67l4.47-5.5h6.53l-5.71 6.6 5.86 9.84Zm14.78.4a8.48 8.48 0 0 1-8.6-8.54v-.06a8.54 8.54 0 0 1 8.81-8.57 7.78 7.78 0 0 1 7 3.68l-3.92 2.91A3.55 3.55 0 0 0 46.18 66 3.41 3.41 0 0 0 43 69.65v.06c0 2.12 1.34 3.73 3.28 3.73a3.92 3.92 0 0 0 3.16-1.7l3.86 2.8a8.16 8.16 0 0 1-7.36 3.77Zm24.62-.4h-5.71v-1.7A6.2 6.2 0 0 1 60 78.28c-3.13 0-5.53-1.8-5.53-5.08v-.06c0-3.4 2.58-5.16 6.44-5.16a12.74 12.74 0 0 1 3.95.63v-.27c0-1.7-1.06-2.67-3.28-2.67a11.52 11.52 0 0 0-4.53.91l-1.19-4.07a16.54 16.54 0 0 1 6.66-1.28c2.95 0 4.95.7 6.26 2s1.76 2.92 1.76 5.23Zm8.85 0h-5.77V55.73h5.77Z" />
        </svg>
      );
    case "vehicle":
      return <div>{infos.code}</div>;
    default:
      return <div>NULL</div>;
  }
};

const renderBackground = (type, infos, options) => {
  switch (type) {
    case "walk":
      return {
        color: "#333",
        height: 18,
        zIndex: 1,
        ...options,
      };
    case "wait":
      return {
        color: "#333",
        height: 15,
        zIndex: 1,
        ...options,
      };
    case "bike":
      return {
        color: "#333",
        height: 20,
        zIndex: 1,
        ...options,
      };
    case "leaf":
    case "leaf-not":
      return {
        color: "#333",
        height: 20,
        zIndex: 1,
        ...options,
      };
    case "calories":
      return {
        color: "#333",
        height: 20,
        zIndex: 1,
        ...options,
      };
    case "elevation-gain":
    case "elevation-loss":
      return {
        color: "#333",
        height: 20,
        zIndex: 1,
        ...options,
      };
    case "vehicle":
      return {
        background: "#" + infos.color,
        ...options,
      };
    default:
      return {};
  }
};

const SectionIcon = ({ type, infos, options, image, className }) => (
  <div className="lc-root-icon" style={renderBackground(type, infos, options)}>
    {renderIcon(type, infos, image, className)}
  </div>
);

export default SectionIcon;
